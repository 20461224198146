body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

nav {
  z-index: 1000;
  background-color: var(--pico-background-color);
  border-bottom: var(--pico-border-width) solid var(--pico-form-element-border-color);
  padding: var(--pico-spacing);
  position: sticky;
  top: 0;
  box-shadow: 0 2px 10px #0000001a;
}

nav .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo {
  color: var(--pico-primary);
  align-items: center;
  gap: calc(var(--pico-spacing) / 2);
  font-family: Pacifico, cursive;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
}

.logo svg {
  width: 1.8rem;
  height: 1.8rem;
}

main {
  padding: var(--pico-spacing);
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.login {
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  display: flex;
}

.content-wrapper {
  gap: var(--pico-spacing);
  flex-direction: column;
  min-height: 80vh;
  display: flex;
}

textarea {
  resize: none;
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
  text-align: center;
  flex: 1;
  padding-top: 30vh;
  font-size: 1rem;
}

textarea::placeholder {
  color: var(--pico-primary);
  opacity: .7;
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
}

.document-view {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: var(--pico-spacing);
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  flex: 1;
  font-size: 1rem;
  overflow-y: auto;
}

.context-menu {
  z-index: 9999;
  position: fixed;
}

.context-menu .card {
  box-shadow: var(--pico-card-box-shadow);
  border-radius: var(--pico-border-radius);
  width: max-content;
  min-width: 200px;
  margin-bottom: 0;
}

.context-menu .grid {
  gap: calc(var(--pico-spacing) / 2);
  flex-direction: column;
  display: flex;
}

.context-menu button {
  text-align: left;
  color: var(--pico-primary);
  padding: calc(var(--pico-spacing) * .75);
  border-radius: var(--pico-border-radius);
  transition: background-color var(--pico-transition), color var(--pico-transition);
  font-weight: var(--pico-font-weight);
  white-space: nowrap;
  background-color: #0000;
  border: none;
  align-items: center;
  width: 100%;
  display: flex;
}

.context-menu button:hover {
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
}

.context-menu button svg {
  margin-right: calc(var(--pico-spacing) * .75);
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.hamburger-btn, .drawer-close, .icon-button, .theme-toggle {
  background-color: var(--pico-primary);
  border: var(--pico-border-width) solid var(--pico-primary);
  border-radius: var(--pico-border-radius);
  cursor: pointer;
  color: var(--pico-primary-inverse);
  transition: background-color var(--pico-transition), transform .1s;
  box-shadow: 0 4px 0 var(--pico-primary-focus);
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
}

.hamburger-btn:hover, .drawer-close:hover, .icon-button:hover, .theme-toggle:hover {
  background-color: var(--pico-primary-hover);
}

.hamburger-btn:active, .drawer-close:active, .icon-button:active, .theme-toggle:active {
  box-shadow: 0 0 0 var(--pico-primary-focus);
  transform: translateY(4px);
}

.hamburger-btn svg, .icon-button svg, .drawer-close svg, .theme-toggle svg {
  width: 24px;
  height: 24px;
}

.drawer {
  background-color: var(--pico-background-color);
  transition: right var(--pico-transition);
  z-index: 2000;
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -300px;
  box-shadow: -5px 0 15px #0000001a;
}

.drawer.open {
  right: 0;
}

.drawer-content {
  padding: var(--pico-spacing);
}

.drawer-close {
  top: var(--pico-spacing);
  right: var(--pico-spacing);
  position: absolute;
}

.drawer-options {
  margin-top: calc(var(--pico-spacing) * 2);
}

.drawer-options a {
  padding: calc(var(--pico-spacing) * .75);
  color: var(--pico-primary);
  transition: background-color var(--pico-transition), color var(--pico-transition);
  border-radius: var(--pico-border-radius);
  font-weight: var(--pico-font-weight);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.drawer-options a:hover {
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
}

.drawer-options a svg {
  margin-right: calc(var(--pico-spacing) * .75);
  width: 20px;
  height: 20px;
}

button {
  font-weight: var(--pico-font-weight);
  text-transform: uppercase;
  border-radius: var(--pico-border-radius);
  border: var(--pico-border-width) solid var(--pico-primary);
  box-shadow: 0 4px 0 var(--pico-primary-focus);
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
  transition: transform .1s, box-shadow .1s;
}

button:hover {
  background-color: var(--pico-primary-hover);
}

button:active {
  box-shadow: 0 0 0 var(--pico-primary-focus);
  transform: translateY(4px);
}

.language-select {
  margin-bottom: var(--pico-spacing);
}

dialog {
  border-radius: var(--pico-border-radius);
  border: none;
  width: 500px;
  max-width: 90%;
  padding: 0;
}

dialog::backdrop {
  background-color: #00000080;
}

.dialog-header {
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
  padding: var(--pico-spacing);
  border-top-left-radius: var(--pico-border-radius);
  border-top-right-radius: var(--pico-border-radius);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.dialog-header h3 {
  align-items: center;
  gap: .5rem;
  margin: 0;
  font-size: 1.2rem;
  display: flex;
}

.dialog-content {
  padding: var(--pico-spacing);
}

.dialog-footer {
  padding: var(--pico-spacing);
  justify-content: flex-end;
  gap: var(--pico-spacing);
  display: flex;
}

.translation-result {
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
  margin-bottom: var(--pico-spacing);
}

.close-dialog {
  color: var(--pico-primary-inverse);
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.close-dialog:hover {
  color: var(--pico-primary-hover);
}

.audio-player {
  align-items: center;
  gap: var(--pico-spacing);
  flex-direction: column;
  display: flex;
}

.audio-controls {
  align-items: center;
  gap: var(--pico-spacing);
  display: flex;
}

.play-button {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
  display: flex;
}

.speed-select {
  align-items: center;
  gap: var(--pico-spacing);
  display: flex;
}

.selected-text {
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
  margin-bottom: var(--pico-spacing);
  max-height: 100px;
  overflow-y: auto;
}

.nav-buttons {
  gap: var(--pico-spacing);
  display: flex;
}

.flashcard-content {
  gap: var(--pico-spacing);
  flex-direction: column;
  display: flex;
}

.flashcard-side {
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
}

.flashcard-actions {
  margin-top: var(--pico-spacing);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flashcard-actions button {
  padding: calc(var(--pico-spacing) / 2) var(--pico-spacing);
}

.app-description {
  text-align: center;
  margin-bottom: var(--pico-spacing);
}

.button-incorrect {
  background-color: var(--pico-color-red-500);
  border-color: var(--pico-color-red-700);
  box-shadow: 0 4px 0 var(--pico-color-red-800);
}

.button-incorrect:hover {
  background-color: var(--pico-color-red-700);
}

.button-correct {
  background-color: var(--pico-color-jade-500);
  border-color: var(--pico-color-jade-700);
  box-shadow: 0 4px 0 var(--pico-color-jade-800);
}

.button-correct:hover {
  background-color: var(--pico-color-jade-700);
}

.card-text {
  text-align: center;
  margin-top: var(--pico-spacing);
  font-size: 1.5rem;
  font-weight: bold;
}

.done-message {
  text-align: center;
  color: #4caf50;
  margin-top: 2rem;
  font-size: 3rem;
  font-weight: bold;
}

audio {
  background-color: var(--pico-background-color);
}

@media (height <= 600px) {
  .logo {
    font-size: 1.2rem;
  }

  .logo svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .hamburger-btn, .icon-button, .drawer-close, .theme-toggle {
    width: 36px;
    height: 36px;
  }

  .hamburger-btn svg, .drawer-close svg, .theme-toggle svg {
    width: 20px;
    height: 20px;
  }

  .app-description {
    font-size: .9rem;
  }
}

[cloak] {
  display: none !important;
}

tr.htmx-swapping td {
  opacity: 0;
  transition: opacity 1s ease-out;
}
/*# sourceMappingURL=styles.css.map */
