body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--pico-background-color);
  border-bottom: var(--pico-border-width) solid var(--pico-form-element-border-color);
  padding: var(--pico-spacing);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-family: Pacifico, cursive;
  font-weight: 400;
  color: var(--pico-primary);
  display: flex;
  align-items: center;
  gap: calc(var(--pico-spacing) / 2);
}

.logo svg {
  width: 1.8rem;
  height: 1.8rem;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--pico-spacing);
  overflow-y: auto;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--pico-spacing);
  min-height: 80vh;
}

textarea {
  flex: 1;
  resize: none;
  font-size: 1rem;
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
  text-align: center;
  padding-top: calc(60vh - 30vh);
}

textarea::placeholder {
  color: var(--pico-primary);
  font-size: 1.1rem;
  font-weight: bold;
  opacity: 0.7;
  width: 100%;
}

.document-view {
  flex: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: var(--pico-spacing);
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  font-size: 1rem;
}

.context-menu {
  position: fixed;
  z-index: 9999;
}

.context-menu .card {
  margin-bottom: 0;
  box-shadow: var(--pico-card-box-shadow);
  border-radius: var(--pico-border-radius);
  width: max-content;
  min-width: 200px;
}

.context-menu .grid {
  display: flex;
  flex-direction: column;
  gap: calc(var(--pico-spacing) / 2);
}

.context-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  color: var(--pico-primary);
  padding: calc(var(--pico-spacing) * 0.75);
  border-radius: var(--pico-border-radius);
  transition: background-color var(--pico-transition), color var(--pico-transition);
  font-weight: var(--pico-font-weight);
  white-space: nowrap;
}

.context-menu button:hover {
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
}

.context-menu button svg {
  margin-right: calc(var(--pico-spacing) * 0.75);
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.hamburger-btn,
.drawer-close,
.icon-button,
.theme-toggle {
  background-color: var(--pico-primary);
  border: var(--pico-border-width) solid var(--pico-primary);
  border-radius: var(--pico-border-radius);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--pico-primary-inverse);
  transition: background-color var(--pico-transition), transform 0.1s;
  box-shadow: 0 4px 0 var(--pico-primary-focus);
  padding: 0;
}

.hamburger-btn:hover,
.drawer-close:hover,
.icon-button:hover,
.theme-toggle:hover {
  background-color: var(--pico-primary-hover);
}

.hamburger-btn:active,
.drawer-close:active,
.icon-button:active,
.theme-toggle:active {
  transform: translateY(4px);
  box-shadow: 0 0 0 var(--pico-primary-focus);
}

.hamburger-btn svg,
.icon-button svg,
.drawer-close svg,
.theme-toggle svg {
  width: 24px;
  height: 24px;
}

.drawer {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: var(--pico-background-color);
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  transition: right var(--pico-transition);
  z-index: 2000;
}

.drawer.open {
  right: 0;
}

.drawer-content {
  padding: var(--pico-spacing);
}

.drawer-close {
  position: absolute;
  top: var(--pico-spacing);
  right: var(--pico-spacing);
}

.drawer-options {
  margin-top: calc(var(--pico-spacing) * 2);
}

.drawer-options a {
  display: flex;
  align-items: center;
  padding: calc(var(--pico-spacing) * 0.75);
  color: var(--pico-primary);
  text-decoration: none;
  transition: background-color var(--pico-transition), color var(--pico-transition);
  border-radius: var(--pico-border-radius);
  font-weight: var(--pico-font-weight);
}

.drawer-options a:hover {
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
}

.drawer-options a svg {
  margin-right: calc(var(--pico-spacing) * 0.75);
  width: 20px;
  height: 20px;
}

button {
  font-weight: var(--pico-font-weight);
  text-transform: uppercase;
  border-radius: var(--pico-border-radius);
  border: var(--pico-border-width) solid var(--pico-primary);
  box-shadow: 0 4px 0 var(--pico-primary-focus);
  transition: transform 0.1s, box-shadow 0.1s;
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
}

button:hover {
  background-color: var(--pico-primary-hover);
}

button:active {
  transform: translateY(4px);
  box-shadow: 0 0 0 var(--pico-primary-focus);
}

.language-select {
  margin-bottom: var(--pico-spacing);
}

dialog {
  border-radius: var(--pico-border-radius);
  border: none;
  padding: 0;
  max-width: 90%;
  width: 500px;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-header {
  background-color: var(--pico-primary);
  color: var(--pico-primary-inverse);
  padding: var(--pico-spacing);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: var(--pico-border-radius);
  border-top-right-radius: var(--pico-border-radius);
}

.dialog-header h3 {
  margin: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dialog-content {
  padding: var(--pico-spacing);
}

.dialog-footer {
  padding: var(--pico-spacing);
  display: flex;
  justify-content: flex-end;
  gap: var(--pico-spacing);
}

.translation-result {
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
  margin-bottom: var(--pico-spacing);
}

.close-dialog {
  background: none;
  border: none;
  color: var(--pico-primary-inverse);
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.close-dialog:hover {
  color: var(--pico-primary-hover);
}

.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--pico-spacing);
}

.audio-controls {
  display: flex;
  align-items: center;
  gap: var(--pico-spacing);
}

.play-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.speed-select {
  display: flex;
  align-items: center;
  gap: var(--pico-spacing);
}

.selected-text {
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
  margin-bottom: var(--pico-spacing);
  max-height: 100px;
  overflow-y: auto;
}

.nav-buttons {
  display: flex;
  gap: var(--pico-spacing);
}

.flashcard-content {
  display: flex;
  flex-direction: column;
  gap: var(--pico-spacing);
}

.flashcard-side {
  background-color: var(--pico-card-background-color);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  padding: var(--pico-spacing);
}

.flashcard-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--pico-spacing);
}

.flashcard-actions button {
  padding: calc(var(--pico-spacing) / 2) var(--pico-spacing);
}

.app-description {
  text-align: center;
  margin-bottom: var(--pico-spacing);
}

.button-incorrect {
  background-color: var(--pico-color-red-500);
  border-color: var(--pico-color-red-700);
  box-shadow: 0 4px 0 var(--pico-color-red-800);
}

.button-incorrect:hover {
  background-color: var(--pico-color-red-700);
}

.button-correct {
  background-color: var(--pico-color-jade-500);
  border-color: var(--pico-color-jade-700);
  box-shadow: 0 4px 0 var(--pico-color-jade-800);
}

.button-correct:hover {
  background-color: var(--pico-color-jade-700);
}

.card-text {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: var(--pico-spacing);
}

.done-message {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  color: #4CAF50;
}

audio {
  background-color: var(--pico-background-color);
}

@media (max-height: 600px) {
  .logo {
    font-size: 1.2rem;
  }

  .logo svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .hamburger-btn,
  .icon-button,
  .drawer-close,
  .theme-toggle {
    width: 36px;
    height: 36px;
  }

  .hamburger-btn svg,
  .drawer-close svg,
  .theme-toggle svg {
    width: 20px;
    height: 20px;
  }

  .app-description {
    font-size: 0.9rem;
  }
}

/* Alpine.js styles */

[cloak] {
  display: none !important;
}

/* HTMX styles */

tr.htmx-swapping td {
  opacity: 0;
  transition: opacity 1s ease-out;
}